<!-- 离职 -->
<template>
    <div>
        <!-- <van-nav-bar title="申请离职" left-text="返回" left-arrow @click-left="onClickLeft" /> -->
        <div class="Dimission">
            <div>
                <div class="dingbuicon" v-if="list.audit&&list.audit.dead_status">
                    <div class="dingbuicon_on">
                        <div class="dingbuicon_on_lt"></div>
                        <div class="dingbuicon_on_rt" v-if="list.audit.dead_status==1">离职申请已通过！</div>
                        <div class="dingbuicon_on_rt" v-if="list.audit.dead_status==2">离职申请已被驳回！</div>
                    </div>
                </div>
                <div v-if="list.incumbent">
                    <div class="staff_info">
                        <!-- <div class="title">在职信息</div> -->
                        <div class="title_news">
                            <div class="title_news_lt">在职信息</div>
                            <div class="title_news_right" v-if="list.incumbent.incumbent_contract&&list.incumbent.incumbent_contract.length>=1" @click="gotofujian(list.incumbent.incumbent_contract)">合同附件</div>
                        </div>
                        <div class="username">
                            <div class="left">
                                在职工厂
                            </div>
                            <div class="right">{{ list.incumbent.factory_name }}</div>
                        </div>
                        <div class="username">
                            <div class="left">
                                入职职位
                            </div>
                            <div class="right">{{ list.incumbent.job }}</div>
                        </div>
                        <div class="username">
                            <div class="left">
                                入职日期
                            </div>
                            <div class="right">{{ list.incumbent.employ_time }}</div>
                        </div>
                        <div class="username" v-if="list.incumbent.employed_days > -1">
                            <div class="left">在职天数</div>
                            <div class="right">{{ list.incumbent.employed_days }}天</div>
                        </div>
                        <div class="username">
                            <div class="left">
                                招聘经纪人
                            </div>
                            <div class="right">{{ list.incumbent.member_name}}</div>
                        </div>
                    </div>
                    <div v-if="list.renewal&&list.renewal.length">
                        <div class="staff_info" v-for="(item,index) in list.renewal" :key="index">
                            <div class="title_news">
                                <div class="title_news_lt">续签信息</div>
                                <div class="title_news_right" v-if="item.renewal_contract&&item.renewal_contract.length>=1" @click="gotofujian(item.renewal_contract)">合同附件</div>
                            </div>
                            <div class="username">
                                <div class="left">
                                    续签日期
                                </div>
                                <div class="right">{{ item.renewal_time	 }}</div>
                            </div>
                            <div class="username">
                                <div class="left">
                                    工期
                                </div>
                                <div class="right">{{ item.period_desc }}</div>
                            </div>
                            <div class="username">
                                <div class="left">
                                    到期日期
                                </div>
                                <div class="right">{{ item.renewal_dead_time }}</div>
                            </div>
                            <div class="username">
                                <div class="left">
                                    续签人
                                </div>
                                <div class="right">{{ item.operator_name }}</div>
                            </div>
                        </div>
                    </div>

                    <div class="staff_info" v-if="list.audit&&(list.audit.apply_time||list.audit.dead_time||list.audit.dead_note)">
                        <div class="title">离职申请信息</div>
                        <div class="username">
                            <div class="left">
                                申请提交时间
                            </div>
                            <div class="right">{{ list.audit.apply_time}}</div>
                        </div>
                        <div class="username">
                            <div class="left">
                                离职日期
                            </div>
                            <div class="right">{{ list.audit.dead_time }}</div>
                        </div>
                        <div class="note_box">
                            <div class="left">
                                离职原因
                            </div>
                            <div class='note'>
                                <van-field v-model="list.audit.dead_note" rows="5" disabled autosize :border="true" type="textarea" maxlength="100" placeholder="请输入离职原因" />
                            </div>
                        </div>
                    </div>
                    <div class="staff_info" v-if="list.dead_audit_data && list.dead_audit_data.audit_time">
                        <div class="title">驻厂审批信息</div>
                        <div class="username">
                            <div class="left">
                                审批时间
                            </div>
                            <div class="right">{{ list.dead_audit_data.audit_time }}</div>
                        </div>
                        <div class="username">
                            <div class="left">
                                审批人
                            </div>
                            <div class="right">{{ list.dead_audit_data.audit_member_name }}</div>
                        </div>
                        <div class="username">
                            <div class="left">
                                审批结果
                            </div>
                            <div class="right">{{ list.dead_audit_data.audit_status_desc }}</div>
                        </div>
                        <div class="username" v-if="list.dead_audit_data.audit_note">
                            <div class="left">
                                审批备注
                            </div>
                            <div class="right">{{ list.dead_audit_data.audit_note }}</div>
                        </div>
                    </div>
                    <!-- <div class="woyilizhi" @click="my_lizhi=true">若你已离职，点击这里更新状态</div> -->
                </div>
            </div>
        </div>
        <!-- 弹窗 -->
        <div class="footer_two_btn">
            <div class="footer_two_btn_cd">
                <div class="footer_two_btn_cd_lt" @click="gobanck">返回</div>
                <div class="footer_two_btn_cd_rt d8" v-if="is_fac == 0">申请离职</div>
                <div class="footer_two_btn_cd_rt" v-else-if="list.audit&&list.audit.dead_status==2" @click="shenqliHI(2)">重新申请</div>
                <div class="footer_two_btn_cd_rt" v-else @click="shenqliHI(1)">申请离职</div>
            </div>
        </div>
        <div class="cancl_tan" v-if="my_lizhi">
            <div class="cancl_tan_on">
                <div class="cancl_tan_on_title">提示</div>
                <div class="cancl_tan_on_cont">
                    <div class="cancl_tan_on_cont_ont">确认您已离职？离职后将无法借支</div>
                </div>
                <div class="cancl_tan_on_bont">
                    <div class="cancl_tan_on_bont_lt" @click="cancle_no">取消</div>
                    <div class="cancl_tan_on_bont_rt" @click="cancle_ok">确认</div>
                </div>
            </div>
        </div>
        <!-- 弹窗 -->
        <!-- -->
        <div class="bottom_back" v-if="list.audit&&list.audit.dead_status==1">
            <div class="bottom_back_on" @click="gobanck">
                <div class="bottom_back_wenz">返回</div>
                <div class="iconfont icon-fanhui"></div>
            </div>
        </div>
    </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { Toast, ImagePreview } from 'vant';

export default {
    //import引入的组件需要注入到对象中才能使用
    name: "Dimission",
    components: {},
    data() {
        //这里存放数据
        return {
            list: {},
            is_click: false,
            my_lizhi: false,
            again: 0, // 第一次申请，所以为0，重新申请就为1
            minDate: new Date(2020, 0, 1),
            maxDate: new Date(2025, 10, 1),
            currentDate: "",
            show: false,
            is_fac: 1, // 有驻厂
            flag: 1,
            is_home: false, // 是否是从home页面过来的

        };
    },

    created() {
        this.$store.commit("getWxSDK");
        this.is_fac = this.$route.query.is_fac
        this.$store.commit("hideTabbar");
        this.huoquzaizhi()
    },
    //方法集合
    methods: {
        gotofujian(data) {
            ImagePreview({
                images: data,
                // startPosition: 1,
            });

            // this.$router.push({ name: 'imgkan', params: { type: JSON.stringify(data) } });
        },
        shenqliHI(type) {
            if (type == 1) {
                let data = {
                    team_id: localStorage.getItem('team_id'),
                }
                this.Request('client/dead/apply', 'get', data, true, false).then(res => {
                    if (res.status == 0) {
                        if (res.data.dead_apply && res.data.dead_apply.status == 0) { // 待审核
                            Toast('您的离职申请正在审核，请您耐心等待工作人员审核');
                        } else {
                            this.$router.push({ path: '/dimission' });
                        }
                    } else if (res.status == -4) {
                        this.$router.push({ path: '/dimission' });
                    }
                })
            } else {
                this.$router.push({ path: '/dimission', query: { again: 1 } });
            }

        },
        //取消
        cancle_no() {
            this.my_lizhi = false;
        },
        cancle_ok() {
            let data = {
                jobseeker_id: localStorage.getItem('jobseeker_id'),
            }
            this.Request('client/jobseeker/regular', 'post', data, true, false).then(res => {
                if (res.status == 0) {
                    this.$router.go(-1)
                }
                this.my_lizhi = false
            })
        },
        gobanck() {
            this.$router.go(-1);
        },
        huoquzaizhi() {
            let data = {
                jobseeker_id: localStorage.getItem('jobseeker_id'),
                team_id: localStorage.getItem('team_id'),
            }
            this.Request('client/jobseeker', 'get', data, true, false).then(res => {
                if (res.status == 0) {
                    this.list = res.data
                }
            })
        },
        confirm(e) {
            this.list.basic.leaveDate = this.getDate(e);
            this.show = false;
        },
        getDate(val) {
            var year = val.getFullYear();
            var month = val.getMonth() + 1;
            month = month < 10 ? "0" + month : month;
            var day = val.getDate();
            day = day < 10 ? "0" + day : day;
            return year + "-" + month + "-" + day;
        },

    },

};
</script>
<style scoped>
.Dimission {
    width: 100vw;
    overflow-y: hidden;
    box-sizing: border-box;
}
.dingbuicon {
    width: 100%;
    height: 60px;
    background: #ecfbfb;
    margin-bottom: 20px;
}
.dingbuicon_on {
    width: calc(100% - 60px);
    height: 60px;
    margin-left: 30px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
}
.dingbuicon_on_lt {
    width: 30px;
    height: 30px;
    background: url("../../assets/img/tishiicon.png");
    background-size: 100% 100%;
}
.dingbuicon_on_rt {
    width: calc(100% - 70px);
    padding-left: 10px;
    height: 40px;
    line-height: 42px;
    text-align: left;
    font-size: 26px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #0abbb9;
}
.woyilizhi {
    width: 365px;
    height: 40px;
    font-size: 26px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 50px;
    color: #0abbb9;
    text-decoration: underline;
    margin: 40px auto;
    margin-bottom: 115px;
}
/* @import url(); 引入公共css类 */
.Dimission {
    width: 750px;
    /* padding-top: 90px; */
    padding-bottom: 100px;
    /* box-sizing: border-box; */
}

.staff_info,
.dimission_info {
    width: 750px;
    font-size: 28px;
    padding: 0 30px;
    box-sizing: border-box;
    background: white;
}
.staff_info {
    padding: 30px 20px;
    margin-bottom: 20px;
    /* padding: 30px; */
}
.dimission_info {
    padding: 30px 30px 40px 30px;
}
.username {
    text-align: left;
    border-bottom: 1px solid #f8f8f8;
    display: flex;
    padding: 30px 0;
    justify-content: space-between;
    align-items: center;
}
.username:last-child {
    border-bottom: 0;
}
.title {
    font-size: 30px;
    text-align: left;
    font-weight: bold;
    color: #333333;
    /* margin-bottom: 10px; */
}
.title_news {
    width: 100%;
    /* height: 60px; */
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
}
.title_news_right {
    /* width: 120px;
    height: 60px;
    line-height: 60px; */
    text-align: right;
    /* font-size: 28px; */
    /* font-family: PingFang SC; */
    font-weight: 400;
    color: #0abbb9;
}
.title_news_lt {
    /* width: 220px;
    height: 60px;
    line-height: 60px; */
    text-align: left;
    /* font-size: 32px; */
    font-family: PingFang SC;
    font-weight: 700;
    color: #1a1a1a;
}
.left {
    /* width: 140px; */
    white-space: nowrap;
    text-align: left;
    margin-right: 20px;
}
.right {
    flex: 1;
    display: flex;
    justify-content: flex-end;
}
.placeholder {
    margin-left: 56px;
}
/* .right {
    color: gray;
} */
.right_icon {
    display: flex;
    width: 30px;
    align-items: center;
    justify-content: flex-end;
}
.note {
    border: 2px solid #f8f8f8;
    border-radius: 10px;
    z-index: 100;
    margin-top: 20px;
    overflow: hidden;
}
.btn {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100px;
}
.note_box {
    margin-top: 20px;
}
.err {
    text-align: center;
    padding-top: 200px;
}
.err_text {
    font-size: 30px;
    width: 500px;
    margin: 20px auto;
}
</style>
